import { Typography, mq } from 'jns-thorin'
import Head from 'next/head'
// import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { twMerge } from 'tailwind-merge'

import TwitterIcon from '@app/assets/icons/brand-x.svg'
import DiscoverIcon from '@app/assets/icons/discover.svg'
import GitHubIcon from '@app/assets/icons/github.svg'
import ManageIcon from '@app/assets/icons/manage.svg'
import RegisterIcon from '@app/assets/icons/register.svg'
import TradeIcon from '@app/assets/icons/trade.svg'
// import LandingPageBg from '@app/assets/images/landing-bg.png'
// import FaucetBanner from '@app/components/@molecules/FaucetBanner'
import Hamburger from '@app/components/@molecules/Hamburger/Hamburger'
import { SearchInput } from '@app/components/@molecules/SearchInput/SearchInput'
import { LeadingHeading } from '@app/components/LeadingHeading'
import { Basic } from '@app/layouts/Basic'

import ENSFull from '../assets/ENSFull.svg'

const Box = ({
  Icon,
  title,
  description,
  style,
  href,
  classNames,
}: {
  Icon?: React.FC
  title: string
  description: string
  style?: React.CSSProperties
  href?: string
  classNames?: string
}) => {
  const Elm = href ? 'a' : 'div'
  return (
    <Elm
      onClick={href ? () => window.open(href, '_blank') : () => {}}
      className={twMerge(
        'md:w-1/4 border border-grey rounded-xl font-sans h-full max-w-96',
        !!href && 'cursor-pointer',
        classNames,
      )}
      style={{
        backgroundImage: 'linear-gradient(to bottom, #180A3E 0%,  #000115 100%)',
        ...style,
      }}
    >
      <div className="flex-col justify-center gap-5 md:flex hidden min-h-48 p-6">
        {Icon && (
          <span className="w-10 h-10 overflow-hidden flex items-center justify-center">
            <Icon />
          </span>
        )}
        <div className="flex flex-col gap-1 max-h-20">
          <h5 className="font-bold text-sm md:text-lg">{title}</h5>
          <span className="text-xs md:text-sm font-light text-primary-100">{description}</span>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center gap-6 md:hidden p-6">
        {Icon && (
          <span className="w-14 h-14 flex items-center justify-center">
            <Icon />
          </span>
        )}
        <div className="flex flex-col w-44">
          <h5 className="font-bold text-sm md:text-lg">{title}</h5>
          <span className="text-xs md:text-sm font-light text-primary-100">{description}</span>
        </div>
      </div>
    </Elm>
  )
}

const GradientTitle = styled.h1(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.headingFour};
    text-align: center;
    font-weight: 800;
    font-family: ElMessiri, sans-serif;
    color: #fff;
    /*    background-image: ${theme.colors.gradients.accent};
    background-repeat: no-repeat;
    background-size: 110%;
    */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-background-clip: text;
    background-clip: text;
    margin: 0;

    ${mq.sm.min(css`
      font-size: ${theme.fontSizes.headingOne};
    `)}
  `,
)

const SubtitleWrapper = styled.div(
  ({ theme }) => css`
    max-width: calc(${theme.space['72']} * 2 - ${theme.space['4']});
    line-height: 150%;
    text-align: center;
    margin-bottom: ${theme.space['3']};
  `,
)

const Container = styled.div(
  () => css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
)

const Stack = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-gap: ${theme.space['3']};
    gap: ${theme.space['3']};
  `,
)

const StyledENS = styled.div(
  ({ theme }) => css`
    height: ${theme.space['8.5']};
  `,
)

const LogoAndLanguage = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: ${theme.space['4']};
    flex-gap: ${theme.space['4']};
  `,
)

const StyledLeadingHeading = styled(LeadingHeading)(
  () => css`
    ${mq.sm.min(
      css`
        display: none;
      `,
    )}
  `,
)

const ContentWrapper = styled.div(
  ({ theme }) => css`
    max-width: ${theme.space['192']};
    width: 100%;
    align-self: center;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.space['4']};
    flex-gap: ${theme.space['4']};
  `,
)

function Page() {
  const { t } = useTranslation('common')

  return (
    <Basic noContentWrapper>
      <Head>
        <title>JNS</title>
      </Head>
      <StyledLeadingHeading>
        <LogoAndLanguage>
          <StyledENS as={ENSFull} />
        </LogoAndLanguage>
        <Hamburger />
      </StyledLeadingHeading>
      {/* <FaucetBanner /> */}
      <section className="w-full flex flex-col justify-center items-center gap-3 md:gap-5">
        <div className="font-light flex gap-2 py-1 px-2 md:py-3 md:px-6 border-solid border-grey rounded-full border text-xs md:text-sm">
          <span>🎉</span> Let&apos;s get started immediately.
        </div>

        <h1 className="font-title text-2xl md:text-6xl">Your Passport to Web3 Simplicity</h1>
        <h5 className="font-sans text-primary-100 md:text-base text-xs text-center">
          Empowering users with simple domain names, JibID enhances security and user experience in
          the blockchain ecosystem.
        </h5>
        <div className="w-full min-h-36 md:h-96 bg-cover bg-center flex justify-center items-center hero" />
      </section>
      <ContentWrapper>
        <Container>
          <Stack>
            {/* YOUR .JIB USERNAME */}
            <GradientTitle>
              Find Your Perfect ID Name
              {/* {t('title')} */}
            </GradientTitle>

            <SubtitleWrapper>
              <Typography fontVariant="small" color="greyLight">
                {/* {t('description')} */}
                Short, memorable, meaningful. Find your perfect match.
              </Typography>
            </SubtitleWrapper>

            <SearchInput />
          </Stack>
        </Container>
      </ContentWrapper>
      <section className="flex flex-col justify-center items-center md:mt-20 mt-10">
        <GradientTitle>Manage Your Web3 Life in One Place</GradientTitle>
        <SubtitleWrapper>
          <Typography fontVariant="small" color="greyLight">
            Start your journey. Everything you need is already here.
          </Typography>
        </SubtitleWrapper>
        <div className="flex justify-center items-center flex-col md:flex-row gap-4 md:px-10 md:min-h-56 w-full px-6">
          <Box
            Icon={DiscoverIcon}
            title="Discover"
            description="A hub for you to search names in all TLDs"
          />
          <Box
            Icon={RegisterIcon}
            title="Register"
            description="Register names from all name services"
          />
          <Box
            Icon={TradeIcon}
            title="Trade"
            description="A marketplace dedicated for domain investors"
          />
          <Box
            Icon={ManageIcon}
            title="Manage"
            description="Manage all your web3 names in one portal"
          />
        </div>
      </section>
      <section className="px-6 md:px-32 mt-10">
        <div
          className="flex flex-col justify-center gap-1 md:gap-4 p-6 md:p-6 border border-grey rounded-xl font-sans md:min-h-48 w-full min-h-20"
          style={{
            background: 'url(/images/get-started.png) no-repeat',
            opacity: 0.8,
            backgroundPosition: 'left center',
            backgroundSize: '130% ',
          }}
        >
          <h5 className="font-bold text-sm md:text-3xl font-title">Get started today</h5>
          <span className="text-xs md:text-sm  text-primary-100 md:w-1/2">
            Say goodbye to restricted usernames. Take control of your username, save your avatar and
            additional profile information, and utilize it across various platforms.
          </span>
        </div>
      </section>
      <section className="flex flex-col justify-center items-center md:mt-20 mt-10 px-6 md:px-32">
        <GradientTitle>Get Connected, Stay Informed</GradientTitle>
        <SubtitleWrapper>
          <Typography fontVariant="small" color="greyLight">
            Connect with the team: Ask questions, get support, and be part of the conversation.
          </Typography>
        </SubtitleWrapper>
        <div className="flex justify-center items-center flex-col md:flex-row gap-4 md:min-h-56 w-full">
          <Box
            classNames="md:w-full md:max-w-full"
            href="https://github.com/JIBID-JNS"
            Icon={GitHubIcon}
            title="Github"
            description="Dive into coding discussions."
          />
          <Box
            classNames=" md:w-full md:max-w-full"
            href="https://twitter.com/JIBID_JNS"
            Icon={TwitterIcon}
            title="Twitter"
            description="Get the latest news, announcements, and insights about JIBID."
          />
        </div>
      </section>
    </Basic>
  )
}

Page.skipBasicLayout = true

export default Page
